import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForgotPassword from "./Pages/ForgotPassword";
import PageNotFound from "./Pages/PageNotFound";
import LinkExpired from "./Pages/LinkExpired";
import ChangedPassword from "./Pages/ChangedPassword";

function App() {
  return(
  <div className="App">
  <BrowserRouter>
  <Routes>
    <Route path="/forgot-password/:token" element={<ForgotPassword/>} />
    <Route path="/forgot-password/link-expired" element={<LinkExpired/>} />
    <Route path="/forgot-password/changed-password" element={<ChangedPassword/>} />
    <Route path="/forgot-password/*" element={<PageNotFound/>} />
  </Routes>
  </BrowserRouter>;
  </div>
  )
}

export default App;
