import React from "react";

import { Box, Typography } from "@mui/material";
import logo from "../utils/Images/ArailaIntroLogo.png";
function LinkExpired() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "95vh",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "200px",
          height: "50px",
        }}
      >
        <img
          style={{
            width: "100%",
            height: "100%",
          }}
          src={logo}
          alt="logo "
        />
      </Box>
      <Typography
        sx={{
          color: "#fff",
          fontSize: { xs: "24px", md: "42px" },
          mt: "40px",
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "24px", md: "42px" },
            color: "Yellow",
          }}
        >
          Ohh
        </Typography>{" "}
        Link Expired !
      </Typography>
    </div>
  );
}

export default LinkExpired;
