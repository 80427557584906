import {  Button } from "@mui/material";
import React from "react";

const CustomBtn = ({btnprops}) => {
  return (
    <>
      <Button
       variant="contained"
        sx={{
          backgroundColor: "#FFFF00",
          color: "#000",
          fontFamily: "Inter", 
          height:{xs:"40px",md:"50px"},
          width:"100%",
          "&:hover": {
            backgroundColor: "#E6D700", // Slightly darker shade of yellow on hover
          },
        }}
      >
        {btnprops}
      </Button>
    </>
  );
};

export default CustomBtn;
