import { FilledInput, IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const PasswordFeild = ({ placeholder, onChange, name, onBlur ,id}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <FilledInput
        id={id}
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        // required
        onBlur={onBlur}
        disableUnderline
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOffOutlinedIcon sx={{ color: "#FFFFFF" }} />
              ) : (
                <RemoveRedEyeOutlinedIcon sx={{ color: "#FFFFFF" }} />
              )}
            </IconButton>
          </InputAdornment>
        }
        sx={{
          width: "100%",
          margin: "auto",
          borderBottom: "1px solid #A2A2A2",
          "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
            borderBottom: "1px solid #A2A2A2",
          },
          "&:hover fieldset": {
            borderBottom: "1px solid #A2A2A2",
          },
          "& .MuiInputBase-input": {
            color: "#FFFFFF",
            fontSize: {xs:"13px",md:"14px"},
            fontWeight: 400,
            lineHeight: "24px",
            padding: "7px 5px",
            outline: "none",
            caretColor: "#FFFFFF",
            "&::placeholder": {
              color: "#FFFFFF",
              opacity: 0.5,
              fontSize: {xs:"13px",md:"14px"},
              fontWeight: 400,
              lineHeight: "10px",
              padding: "2px 0px 0px 0px",
            },
          },
          "& .MuiInput-underline:before, & .MuiInput-underline:after": {
            borderBottom: "none",
          },
          "&:focus": {
            outline: "none",
          },
          "&.Mui-focused fieldset": {
            borderBottom: "1px solid #A2A2A2",
            borderColor: "#FFFF",
          },
        }}
        inputProps={{ autoComplete: "off" }} // Add this to disable browser autocomplete
      />
    </div>
  );
};

export default PasswordFeild;
