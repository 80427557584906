import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { checkToken, forgotPassword } from "../API/forgot_password";
// import { Circles } from "react-loader-spinner";
import { Box, Button, Typography } from "@mui/material";
import PasswordFeild from "../Component/InputFields/PasswordFeild";

import applogo from "../utils/Images/ArailaIntroLogo.png";
import CustomBtn from "../Component/Button/CustomBtn";

function ForgotPassword() {
  const [isButtonLoading, setButtonLoader] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setconfirmPassword] = React.useState("");
  const [passwordError, setPasswordError] = useState("");

  const [ConfirmpasswordError, setConfirmPasswordError] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();
  const validatePassword = () => {
    if (password.length < 1) {
      setPasswordError(" ");
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long.");
    } else {
      setPasswordError("");
    }
  };

  const validateConfirmPassword = () => {
    if (confirmPassword.length < 1) {
      setConfirmPasswordError("");
    } else if (confirmPassword !== password) {
      setConfirmPasswordError(
        "Please make sure password and confirm password are match."
      );
    } else {
      setConfirmPasswordError("");
    }
  };

  const passwordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
    setPasswordError("");
  };

  const confirmPasswordHandler = (e) => {
    e.preventDefault();
    setconfirmPassword(e.target.value);
    setConfirmPasswordError("");
  };

  async function checkTokenApi(token) {
    const data = await checkToken(token);

    if (data) {
      if (data.data["s"] === 0) {
        navigate("/forgot-password/link-expired");
      }
    }
    setButtonLoader(false);
  }
let checkmytoken=()=>{
  checkTokenApi(token);
}
  

  const onClick = async () => {

    if (!password) {
      setPasswordError("Please enter password");
    } else if (!confirmPassword) {
      setPasswordError("Please enter confirm password");
    } else if (password.length < 6) {
      setPasswordError("Password Should be more than six characters");
    } else {
      setButtonLoader(true);
      const data = await forgotPassword(password, token);

      setButtonLoader(false);
      if (data) {
        setButtonLoader(false);
        if (data.data["s"] === 1) {
          navigate("/forgot-password/changed-password");
        }
      }
    }
  };
  
  useEffect(() => {
    checkmytoken()
  },[])
  
  return (
    <>
      <Box
        sx={{
          margin: "auto",
          height: "97.5vh",
          pt: { xs: "150px", md: "100px" },
          width: { xs: "90%", md: "30%" },
          boxSizing: "border-box",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          justifiyContent: "center",
          alignItem: "center",
        }}
      >
        {/* ----------Form Input Fields ------------ */}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: { xs: "30px", md: "25px" },
            marginTop: { xs: "1rem", md: "1.51rem " },
            padding: "20px",
            border: "1px solid #3C3C3C",
            boxSizing: "border-box",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              width: "150px",
              display: "flex",
              margin: "auto",
              padding: "20px",
              borderRadius: "40px",
            }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={applogo}
              alt="App Logo"
            />
          </Box>

          <Typography
            sx={{
              fontSize: { xs: "24px", md: "42px" },
              color: "yellow",
              textAlign: "center",
            }}
          >
            Change Password
          </Typography>
          {/* --------- Password ----- */}
          <Box sx={{ position: "relative" }}>
            <PasswordFeild
              placeholder={"Enter Password"}
              name={"password"}
              lable="Password"
              onChange={passwordHandler}
              value={password}
              onBlur={validatePassword}
              id={"filled-adornment-password"}
            />

            {passwordError && (
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  position: "absolute",
                  mt: { xs: "3px", md: "5px" },
                  color: "red",
                }}
                className="error"
              >
                {passwordError}
              </Typography>
            )}
          </Box>
          <Box sx={{ position: "relative" }}>
            <PasswordFeild
              placeholder={"Confirm Password"}
              name={"ConformPassword"}
              lable="Confirm Password"
              onChange={confirmPasswordHandler}
              value={confirmPassword}
              onBlur={validateConfirmPassword}
              id={"filled-adornment-password2"}
            />

            {confirmPassword && (
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  position: "absolute",
                  mt: { xs: "3px", md: "5px" },
                  color: "red",
                }}
                className="error"
              >
                {ConfirmpasswordError}
              </Typography>
            )}
          </Box>

          {/* ------------Create Btnd----------------- */}

          <Button onClick={onClick} sx={{ width: "100%", mt: "20px" }}>
            <CustomBtn
              btnprops={!isButtonLoading ? "Change Password" : "Loading.."}
            />
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default ForgotPassword;
